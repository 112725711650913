import React from "react"
import { useState } from "react"; 
import Layout from "../components/layout"
import Actualite from "../components/actualite"
import Publication from "../components/publication"
import scrollTo from 'gatsby-plugin-smoothscroll';
import Scrollspy from 'react-scrollspy'
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { graphql } from "gatsby"
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare);



const PublicationsPage =  ( props ) => {

  if (typeof window !== `undefined`) {
    window.onscroll = function () {

     let currentScrollPos = window.pageYOffset;
  
     if(currentScrollPos > 500 ) {
        document.getElementById("mainNav").classList.add("navbar-shrink");
     }
     else {

        document.getElementById("mainNav").classList.remove("navbar-shrink");
     }
   
  }
 }

 let publications = props.data.allParagraphParagrapheTextesImagesD.edges;
 let publ = props.data.allParagraphParagrapheTextesImagesG.edges;
 const listPublications = [];
   for (let index = 0; index < publications.length; index++) {

    if(publications[index].node.field_paragraph_ordre_img_d != null ) {

        listPublications.push(publications[index]);
    }

    if(publ[index] && publ[index].node.field_paragraph_ordre_img_g != null ) {

        listPublications.push(publ[index]);

    }

  }
 
   return (
       
       <Layout id="page-top">
           <nav className="navbar navbar-expand-lg navbar-dark fixed-top " id="mainNav" >
           <div className="container">
               <a className="navbar-brand js-scroll-trigger" style={{cursor:"pointer"}} href='/' onClick={() => scrollTo('#page-top')}><img src="/images/logo.png" alt="" /></a>
               <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                   <i className="fas fa-bars ml-1"></i>
               </button>
               <div className="collapse navbar-collapse" id="navbarResponsive">
               <Scrollspy className="navbar-nav text-uppercase ml-auto" items={ ['actualites', 'jeudedonnees', 'publications'] } currentClassName="is-current">
               <li className="nav-item"><a className="nav-link js-scroll-trigger"style={{cursor:"pointer"}} href='/' onClick={() => scrollTo('/#actualites')}>Actualités</a></li>
                       <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href='/#jeudedonnees' >Jeu de données</a></li>
                       <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href='/#publications' >Publications</a></li>
                       <li className="nav-item"><a target ="_blank" className="nav-link js-scroll-trigger" href="https://www.geograndest.fr/portail/fr/contact">Contact</a></li>
                       <li className="nav-item"><a target ="_blank" className="nav-link js-scroll-trigger" href="https://grandest.data4citizen.com/">GE CMS</a></li>
                   
               </Scrollspy>
                  
               </div>
           </div>
       </nav>
    
       <header className="masthead">
          <div className="container">
                <div className="masthead-subheading">Bienvenue sur le site Open Data!</div>
                <div className="masthead-heading text-uppercase">GrAND EST</div>
             
          </div>
       </header>
           <SEO title="publications" />


       <section className="page-section bg-light" id="publications">
           <div className="container">
               <div className="text-center">
                   <h2 className="section-heading text-uppercase">Nos publications</h2>
                   <h3 className="section-subheading text-muted"></h3>
               </div>
               <div className="row">
               { 
                                
                                listPublications.map((publication, i) => {
                                   
                                   let userData = publication.node;
                                     
                           
                                       return (
                                        <Publication keypub={i} children={userData} detailStatus="true" />
                                         
                                       )
                                 
                                   }
                                   
                                   
                               )

                               }

               </div>
               
           </div>
       </section>

       <div class="py-5 jeudedonnes">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-sm-6 my-3">
                    <a href="http://www.prefectures-regions.gouv.fr/grand-est/" target="_blank" rel="noreferrer"><img style={{width:150+"px", height: 70+"px"}} class="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/logo_bm_reg-grand-est-sur-gge.png" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="https://www.grandest.fr"><img class="img-fluid d-block mx-auto" style={{width:150+"px", height: 70+"px"}} src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/grandest.png" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="https://europa.eu/european-union/index_fr"><img style={{width:150+"px", height: 70+"px"}} class="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/embleme_ue_titre_bleu.png" alt="" /></a>
                    </div>
                    <div class="col-md-3 col-sm-6 my-3">
                        <a href="https://www.grandest.fr/europe-grandest" target="_blank" rel="noreferrer"><img class="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/grand_est_feder_deroule-01.jpg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>

       <section className="page-section bg-dark text-white">
            <div className="container text-center">
                <h2 className="mb-4">FAIT LE PREMIER PAS!</h2>
                <a className="btn btn-light btn-xl" href="https://www.geograndest.fr/portail/fr/contact" target="_blank">CONTACTEZ-NOUS</a>
            </div>
        </section>
                <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-left"><strong>GrandEst</strong> © Tous droits réservés 2020</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                    <a className="btn btn-dark btn-social mx-2"  rel="noreferrer" href="https://www.facebook.com/regiongrandest" target="_blank" rel="noreferrer" title="GrandEst on Facebook"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'facebook']} /> </a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.instagram.com/laregiongrandest/" target="_blank" rel="noreferrer" title="GrandEst on instagram"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'instagram']} /> </a>   
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://twitter.com/regiongrandest?lang=fr" target="_blank" rel="noreferrer" title="GrandEst on Twitter"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'twitter']} /></a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.linkedin.com/organization-guest/company/regiongrandest?challengeId=AQGIJdTmgQRQAQAAAXMzws4rWaGVPB5eUG24az3sAMsZolpawWcG4um0fs1WTvdukzeRwSfup8kKB_fSS3_dNgn7ZQhzJSOvSw&amp;submissionId=d1e1cf2c-8f18-2016-45ae-34bbea0ab656" target="_blank" rel="noreferrer" title="GrandEst on LinkedIn"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'linkedin']} /></a>            
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.youtube.com/RegionGrandEst" target="_blank" rel="noreferrer" title="GrandEst on Youtube"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'youtube']} /></a>    
                    </div>
                    <div className="col-lg-4 text-lg-right">
                        <a className="mr-3"href="https://grandest-monitoring.data4citizen.com/" target="_blank" target="_blank">Monitoring</a>
                        <a href="https://grandest.data4citizen.com/mentions_legales" target="_blank">Mentions Légales</a>
                    </div>
                </div>
            </div>
        </footer>
                    
         </Layout>
           );
}
  

   
  

   
    

export default PublicationsPage
export const query = graphql`
{
 
    allParagraphParagrapheTextesImagesG(sort: {fields: field_paragraph_ordre_img_g, order: ASC}, filter: {field_txt: {elemMatch: {value: {ne: "null"}}}}) {
        edges {
         node {
           id
           field_paragraph_ordre_img_g
           created(formatString: "DD/MM/YYYY")
           field_texte_entete_img_g {
             value
           }
           field_txt {
             value
           }
           field_texte_sous_titre_d {
             value
           }
           relationships {
             field_image_g {
               uri {
                 url
                 value
               }
             }
           }
         }
        }
     
       },
       allParagraphParagrapheTextesImagesD(sort: {fields: field_paragraph_ordre_img_d, order: ASC}) {
         edges {
           node {
             id
             field_paragraph_ordre_img_d
             created(formatString: "DD/MM/YYYY")
             field_texte_entete {
               value
             }
             field_texte_g {
               value
             }
             relationships {
               field_image_d {
                 uri {
                   url
                   value
                 }
               }
             }
             
             parent_field_name
             
           }
         }
         totalCount
       }
    }
`;
